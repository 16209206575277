import {Grid, Login as LoginComponent} from '@coolblue-development/becky';
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import {Paper, Typography, Button, TextField} from '@mui/material';
import styled from '@emotion/styled';
import { Logo } from '../components/Logo';
import React, {ReactElement, useState} from "react";
import {Field, Form} from "react-final-form";
import {initializeApp} from "firebase/app";
import {LegoBrick, User} from "../types";
import {collection, getDocs, getFirestore} from "firebase/firestore";
import {getCookie, setCookie} from "../utils/cookie";


const Login = (): JSX.Element => {
    const firebaseConfig = {
        apiKey: "AIzaSyDr9kK4sdp0GccOMwiCrhSMXctTpzMxMIw",
        authDomain: "lego-bd10d.firebaseapp.com",
        databaseURL: "https://lego-bd10d-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "lego-bd10d",
        storageBucket: "lego-bd10d.appspot.com",
        messagingSenderId: "573203224686",
        appId: "1:573203224686:web:1fb2ef02c9081d7e1eae7f"
    };

    initializeApp(firebaseConfig);

    const [data, setData] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchData = async () => {
        const db = getFirestore();
        const colRef = collection(db, 'users');

        const users = await getDocs(colRef)

        let filteredUsers: any[] = [];

        users.docs.forEach((doc: { data: () => any; id: any; }) => {
            filteredUsers.push({ ...doc.data(), id: doc.id })
        });

        setData(filteredUsers);
    }

    if (data.length === 0) {
        fetchData().then(r => setLoading(false));
    }

    function onSubmit(values: { password: any; }) {
        data.forEach((user: User) => {
            if (user.password === values.password) {
                setCookie("user_id", user.id);
                setCookie("user_name", user.name);
                setCookie("user_role", user.username);
                window.location.reload();
            }
        });
    }

  return (
      <>
     <StyledBox>
          <ResponsiveImage src="logo.png" alt="Lego avatar"/>
          <Typography variant={"h1"}>Lego finder</Typography>
          <Typography variant={"subtitle1"}>To use lego finder you need to login</Typography>
          <br/>

          <Form
              onSubmit={onSubmit}
              render={({handleSubmit}) => (
                  <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                              <Field
                                  name="password"
                                  render={({ input, meta }): ReactElement => (
                                      <>
                                          <TextField
                                              fullWidth
                                              type={'password'}
                                              label="Password"
                                              error={!meta.pristine && !!meta.error}
                                              helperText={!meta.pristine && meta.error}
                                              {...input}
                                              required
                                          />
                                      </>
                                  )}
                              />
                          </Grid>
                      </Grid>
                      <p></p>
                      <Grid item xs={12}>
                          <center>
                              <Button variant="contained" size={"large"} onClick={handleSubmit}>Login</Button>
                          </center>
                      </Grid>
                  </form>
              )}
          />
    </StyledBox>
      </>
  );
};

const ResponsiveImage = styled.img`
  max-width: 45%;
  height: auto;
  display: block; // Removes any whitespace below the image
`;

const StyledBox = styled(Paper)`
  margin: 10vh auto;
  padding: 50px 0px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 600px) {
    box-shadow: none;
    background-color: transparent;
  }
`;

export default Login;
