import { createContext, Dispatch, useReducer } from 'react';
import type { DispatchProps, ServiceContextState } from '../types';

export const initialState: ServiceContextState = {
  serviceSearchQuery: '',
};

export const SET_SERVICE_SEARCH_QUERY = 'SET_SERVICE_SEARCH_QUERY';

export const ServiceContext = createContext<ServiceContextState>(initialState);
export const ServiceDispatchContext = createContext<Dispatch<DispatchProps<string | boolean>>>(() => {
  return;
});

export const serviceReducer = (
  state: ServiceContextState,
  action: DispatchProps<string | boolean>,
): ServiceContextState => {
  switch (action.type) {
    case SET_SERVICE_SEARCH_QUERY: {
      return {
        ...state,
        serviceSearchQuery: action.payload as string,
      };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
};

export const ServiceContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [context, dispatch] = useReducer(serviceReducer, initialState);

  return (
    <ServiceContext.Provider value={context}>
      <ServiceDispatchContext.Provider value={dispatch}>{children}</ServiceDispatchContext.Provider>
    </ServiceContext.Provider>
  );
};
