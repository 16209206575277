export const routes = {
  ROOT: '/',
  ADD: '/add',
  LOGIN: '/login',
  CALCULATOR: '/calculator',
  INITIALIZE: '/initialize/:drawer',
  BRICK_DETAILS: '/:brickId',
  SETTINGS: '/settings',
};

export const getInitializeRoute = (drawer: string): string =>
    routes.INITIALIZE.replace(':drawer', drawer);
