import React, {ChangeEventHandler, ReactElement, useContext, useEffect, useState} from "react";
import { initializeApp } from "firebase/app";
import {
    Box, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Drawer,
    Grid,
    IconButton,
    MenuItem
} from "@coolblue-development/becky";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Fab, FormControl,
    InputAdornment, InputLabel, Select, SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Add from "./Add";
import {collection, getFirestore, query, where, getDocs, deleteDoc, doc, updateDoc, addDoc} from "firebase/firestore";
import {isProduction, setCookie} from "../utils/cookie";
import {Drawers, LegoBrick, User} from "../types";
import LoadingBar from "./shared/LoadingBar";
import {Cross} from "@coolblue-development/icons";
import {getInitializeRoute} from "../routes";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import BrickDetails from "../layouts/BrickDetails";
import {Field, Form} from "react-final-form";

const Settings = (): ReactElement => {
    const [isLoading, setIsLoading] = useState(true);
    const [warningOpen, setWarningOpen] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerToDelete, setDrawerToDelete] = useState('');
    const [drawerIdToDelete, setDrawerIdToDelete] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [drawerTypeToDelete, setDrawerTypeToDelete] = useState('');
    const [data, setData] = useState<Drawers[]>([]);
    const [brickData, setBrickData] = useState<LegoBrick[]>([]);
    const db = getFirestore();

    const fetchData = async () => {
        const colRef = collection(db, 'drawers');

        const drawers = await getDocs(colRef)

        let filteredDrawers: any[] = [];

        drawers.docs.forEach((doc: { data: () => any; id: any; }) => {
            filteredDrawers.push({ ...doc.data(), id: doc.id })
        });

        setData(filteredDrawers.sort((a, b) => {
            // Compare by type
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;

            // If types are equal, compare by name
            return a.name.localeCompare(b.name);
        }));
    }

    const fetchBrickData = async () => {
        let colRef;

        if (isProduction()) {
            colRef = collection(db, 'bricks');
        }
        else {
            colRef = collection(db, 'bricks_testing');
        }

        const bricks = await getDocs(colRef)

        let filteredBricks: any[] = [];

        bricks.docs.forEach((doc: { data: () => any; id: any; }) => {
            filteredBricks.push({ ...doc.data(), id: doc.id })
        });

        setBrickData(filteredBricks);
    }

    if (data.length === 0) {
        fetchData().then(() => {
            fetchBrickData().then(r => setIsLoading(false))
        });
    }

    const handleTypeChange = (event: SelectChangeEvent<unknown>) => {
        setSelectedType(event.target.value as string);
        setSelectedName('')
    };

    const onSubmit = (values: { name: any; type: any; }) => {
        const db = getFirestore();

        const newDrawer = {
            name: values.name,
            type: selectedType
        }

        const colRef = collection(db, 'drawers');

        addDoc(colRef, newDrawer).then(() => {
            window.location.reload();
        });
    }

    if (isLoading) return <LoadingStateComponent />;

    return (
        <>
            <Box display="flex" gap={6} p={5} marginBottom={"2rem"} style={{ overflow: "auto"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={11}>
                        <Typography variant={"h1"}>Settings</Typography>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Typography variant={"h3"}>Storages</Typography>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Button variant="contained" fullWidth color="info" onClick={() => {setShowDrawer(true)}}>
                                    Add new storage
                                </Button>
                            </Grid>
                            {data.map((drawer: Drawers) => (
                                <Grid item xs={12} sm={3}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {drawer.type === 'pab' ? 'PAB'+drawer.name : drawer.name}
                                            </Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                {drawer.type}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color={'error'} onClick={() => {
                                                setDrawerToDelete(drawer.name);
                                                setDrawerIdToDelete(drawer.id);
                                                setDrawerTypeToDelete(drawer.type);
                                                setWarningOpen(true);
                                            }}>Delete</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

            </Box>
            <Dialog open={warningOpen} onClose={() => {setWarningOpen(false)}}>
                <DialogTitle>
                    Are you sure you want to delete {drawerTypeToDelete} {drawerToDelete}?
                    <IconButton onClick={() => {setWarningOpen(false)}} preset="text" size="small" aria-label="Close dialog">
                        <Cross />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>This will delete {drawerTypeToDelete} {drawerToDelete} and set all assigned bricks to <strong>empty</strong></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => {
                        setWarningOpen(false)
                    }}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="error" onClick={() => {
                        const bricksToDelete = brickData.filter((brick: LegoBrick) => {
                            if (brick.storageId === drawerToDelete) {
                                return true;
                            }

                            return extractLetters(brick.storageId) === drawerToDelete;
                        });

                        bricksToDelete.forEach((brick: LegoBrick) => {
                            if (isProduction()) {
                                updateDoc(doc(db, 'bricks', brick.id), {...brick, storageId: '0'}).then(() => {
                                    setIsLoading(true);
                                    fetchData().then(r => setIsLoading(false));
                                });
                            }
                            else {
                                updateDoc(doc(db, 'bricks_testing', brick.id), {...brick, storageId: '0'}).then(() => {
                                    setIsLoading(true);
                                    fetchData().then(r => setIsLoading(false));
                                });
                            }
                        })

                        deleteDoc(doc(db, 'drawers', drawerIdToDelete)).then(() => {
                            setIsLoading(true);
                            fetchData().then(r => setIsLoading(false));
                            fetchBrickData().then(r => setIsLoading(false));
                        });

                        setWarningOpen(false);
                    }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {showDrawer && (
                <Drawer
                    open
                    onClose={(): void => setShowDrawer(false)}
                    title={'Add new storage'}
                >
                    <Form
                        onSubmit={onSubmit}
                        render={({handleSubmit}) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Field
                                            name="type"
                                            render={({ input, meta }): ReactElement => (
                                                <FormControl fullWidth error={!meta.pristine && !!meta.error}>
                                                    <InputLabel>Type</InputLabel>
                                                    <Select
                                                        {...input}
                                                        required
                                                        type={'ewr'}
                                                        value={selectedType}
                                                        onChange={handleTypeChange}
                                                    >
                                                        <MenuItem value="drawer">Drawer</MenuItem>
                                                        <MenuItem value="pab">Pick a brick</MenuItem>
                                                    </Select>
                                                    {!meta.pristine && meta.error && <p>{meta.error}</p>}
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Field
                                            name="name"
                                            validate={(value) => {
                                                if (selectedType === 'pab') {
                                                    if (!/^\d+$/.test(value)) {
                                                        return 'Pick a brick number should only contain numbers';
                                                    }

                                                    if (value > 12) {
                                                        return 'Pick a brick names should not exceed 12';
                                                    }

                                                    const nameExists = data.some((drawer: Drawers) => drawer.name === String(value));
                                                    if (nameExists) {
                                                        return 'Pick a brick number already exists';
                                                    }
                                                }

                                                if (selectedType === 'drawer') {
                                                    const nameExists = data.some((drawer: Drawers) => drawer.name === String(value));
                                                    if (nameExists) {
                                                        return 'Pick a brick number already exists';
                                                    }
                                                }
                                            }}
                                            render={({ input, meta }): ReactElement => (
                                                <>
                                                    <TextField
                                                        fullWidth
                                                        disabled={!selectedType}
                                                        type={selectedType === 'pab' ? 'number' : 'text'}
                                                        label={selectedType === 'pab' ? 'Pick a brick number' : 'Drawer letter'}
                                                        error={!meta.pristine && !!meta.error}
                                                        helperText={!meta.pristine && meta.error}
                                                        {...input}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            const value = event.target.value as string;

                                                            if (selectedType === 'pab') {
                                                                input.onChange(value);
                                                            }
                                                            else {
                                                                if (!/\d/.test(value)) {
                                                                    input.onChange(value.charAt(value.length-1).toUpperCase());
                                                                }
                                                            }
                                                        }}
                                                        required
                                                    />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <center>
                                            <Button fullWidth variant="outlined" size={"large"} type="submit">Add</Button>
                                        </center>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    />
                </Drawer>
            )}
        </>
    )
}

function extractLetters(input: string): string {

    const letters = input.match(/[A-Za-z]/g) || [];

    if (letters.join('') === 'PAB') {
        return input;
    }

    return letters.join('');
}

const LoadingStateComponent = (): ReactElement => (
    <Container>
        <Box p={5}>
            <LoadingBar height={50} width={'100%'}></LoadingBar>
        </Box>
        <Box px={5} py={2}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
        <Box px={5} py={2}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
        <Box px={5} py={2}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
    </Container>
);

export default Settings;