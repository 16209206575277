export const highlightSearchQuery = (text: string, query: string): JSX.Element => {
  if (query === '') {
    return <span>{text}</span>;
  }

  const lowerCase = text.toLowerCase();
  const startPosition = lowerCase.indexOf(query.toLowerCase());

  if (startPosition < 0) {
    return <span>{text}</span>;
  }

  const frontPart = text.substring(0, startPosition);
  const queryPart = text.substring(startPosition, startPosition + query.length);
  const lastPart = text.substring(startPosition + query.length, text.length);

  return (
    <span>
      {frontPart}
      <strong>{queryPart}</strong>
      {lastPart}
    </span>
  );
};

export const booleanValueToString = (value: boolean): string => (value ? 'Yes' : 'No');


export const isStringWithLength = (value: string, minLength: number, maxLength: number): boolean => {
  return Boolean(value) && value.length >= minLength && value.length <= maxLength;
};
