import React, {ReactElement, useContext, useEffect, useState} from "react";
import {GridColDef, GridRowParams, GridValueGetterParams} from "@mui/x-data-grid-premium";
import {LegoBrick} from "../types";
import {highlightSearchQuery} from "../utils/string";
import {useNavigate} from "react-router-dom";
import {ServiceContext, ServiceDispatchContext, SET_SERVICE_SEARCH_QUERY} from "../contexts/serviceContext";
import {InputAdornment, SelectChangeEvent, Box, Select, Button, IconButton, MenuItem, TextField} from "@mui/material";
import {
    DataGrid,
    Divider,
    Typography,
    Grid, Drawer
} from "@coolblue-development/becky";
import LoadingBar from "./shared/LoadingBar";
import {Magnifier, Plus, Refresh} from "@coolblue-development/icons";
import styled from "@emotion/styled";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDocs, updateDoc, addDoc } from "firebase/firestore";
import BrickDetails from "../layouts/BrickDetails";
import {GridComparatorFn, GridPreProcessEditCellProps} from "@mui/x-data-grid-pro";
import {isProduction} from "../utils/cookie";

const firebaseConfig = {
    apiKey: "AIzaSyDr9kK4sdp0GccOMwiCrhSMXctTpzMxMIw",
    authDomain: "lego-bd10d.firebaseapp.com",
    databaseURL: "https://lego-bd10d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lego-bd10d",
    storageBucket: "lego-bd10d.appspot.com",
    messagingSenderId: "573203224686",
    appId: "1:573203224686:web:1fb2ef02c9081d7e1eae7f"
};

initializeApp(firebaseConfig);

const columns: (search: string) => GridColDef[] = (search) => [
    {
        field: 'storageId',
        headerName: 'ID',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<string, LegoBrick>) => `${params.row.storageId || 0}`,
        headerAlign: 'left',
        minWidth: 100,
        maxWidth: 130,
        align: 'left',
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            if (/[A-Za-z]\d/.test(params.props.value)) {
                return { ...params.props, error: false };
            }
            else if (params.props.value === '0') {
                return { ...params.props, error: false };
            }

            return { ...params.props, error: true };
        },
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        headerAlign: 'left',
        minWidth: 240,
        renderCell: (params) => <>{highlightSearchQuery(params.value, search)}</>,
    },
    {
        field: 'color',
        headerName: 'Color',
        flex: 1,
        headerAlign: 'left',
        minWidth: 150,
        renderCell: (params) => <>{capitalizeFirstLetter(params.value)}</>,
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        headerAlign: 'left',
        minWidth: 150,
        renderCell: (params) => <>{capitalizeFirstLetter(params.value)}</>,
    },
    {
        field: 'subType',
        headerName: 'sub Type',
        flex: 1,
        headerAlign: 'left',
        minWidth: 150,
        renderCell: (params) => <>{capitalizeFirstLetter(params.value)}</>,
    },
];

const LegoPartsList = (): ReactElement => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<LegoBrick[]>([]);
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerData, setDrawerData] = useState<LegoBrick>({} as LegoBrick);

    const navigate = useNavigate();
    const fetchData = async () => {
        const db = getFirestore();

        let colRef;

        if (isProduction()) {
            colRef = collection(db, 'bricks');
        }
        else {
            colRef = collection(db, 'bricks_testing');
        }

        const bricks = await getDocs(colRef)

        let filteredBricks: any[] = [];

        bricks.docs.forEach((doc: { data: () => any; id: any; }) => {
            filteredBricks.push({ ...doc.data(), id: doc.id })
        });

        setData(filteredBricks);
    }

    if (data.length === 0) {
        fetchData().then(r => setIsLoading(false));
    }

    const dispatch = useContext(ServiceDispatchContext);
    const { serviceSearchQuery: search } = useContext(ServiceContext);

    const [color, setColor] = useState('');
    const [type, setType] = useState('');
    const [subType, setSubType] = useState('');

    const handleColorChange = (event: SelectChangeEvent<unknown>): void => {
        setColor(event.target.value as string);
    };

    const handleTypeChange = (event: SelectChangeEvent<unknown>): void => {
        setType(event.target.value as string);
        setSubType('');
    };

    const handleSubTypeChange = (event: SelectChangeEvent<unknown>): void => {
        setSubType(event.target.value as string);
    };

    const setSearch = (query: string): void => {
        dispatch({
            type: SET_SERVICE_SEARCH_QUERY,
            payload: query,
        });
    };

    if (isLoading) return <LoadingStateComponent />;

    const colorFilteredList = data.filter((item) => {
        return item.color && item.color.toLowerCase().includes(color.toLowerCase());
    });

    const typeFilteredList = colorFilteredList.filter((item) => {
        return item.type && item.type.toLowerCase().includes(type.toLowerCase());
    });

    const subTypeFilteredList = typeFilteredList.filter((item) => {
        return item.subType && item.subType.toLowerCase().includes(subType.toLowerCase());
    });

    const filteredList = subTypeFilteredList.filter((item) => {
        if (/[A-Za-z]\d/.test(search)) {
            return item.storageId && String(item.storageId) === search;
        }

        return item.name && item.name.toLowerCase().includes(search.toLowerCase());
    });

    const uniqueColors = new Set(filteredList.map(item => item.color.toLowerCase()));

    const colorItems = Array.from(uniqueColors).map(color => ({
        value: color,
        displayValue: color.charAt(0).toUpperCase() + color.slice(1),
    }));

    colorItems.unshift({
        value: '',
        displayValue: 'All',
    });

    const uniqueTypes = new Set(filteredList.map(item => item.type.toLowerCase()));

    const typeItems = Array.from(uniqueTypes).map(type => ({
        value: type,
        displayValue: type.charAt(0).toUpperCase() + type.slice(1),
    }));

    typeItems.unshift({
        value: '',
        displayValue: 'All',
    });

    const uniqueSubTypes = new Set(filteredList.map(item => item.subType.toLowerCase()));

    const subTypeItems = Array.from(uniqueSubTypes).map(subType => ({
        value: subType,
        displayValue: subType.charAt(0).toUpperCase() + subType.slice(1),
    }));

    subTypeItems.unshift({
        value: '',
        displayValue: 'All',
    });

    function getColorItemDisplayValue(val: string): string {
        const match = colorItems.find((item) => item.value === val);
        if (match) {
            return match.displayValue;
        }
        return '';
    }

    function getTypeItemDisplayValue(val: string): string {
        const match = typeItems.find((item) => item.value === val);
        if (match) {
            return match.displayValue;
        }
        return '';
    }

    function getSubTypeItemDisplayValue(val: string): string {
        const match = subTypeItems.find((item) => item.value === val);
        if (match) {
            return match.displayValue;
        }
        return '';
    }

    const handleCellClick = (params: { field: any; row: any; }, event: any) => {
        if (params.field === 'storageId') {
            return;
        }
        setShowDrawer(true);
        setDrawerData(params.row);
    };

    // @ts-ignore
    // @ts-ignore
    return (
        <>
            <Box display="flex" gap={6} p={5} style={{ overflow: "auto"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="searchService"
                            placeholder="Search storage ID or name"
                            InputProps={{
                                type: 'search',
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Magnifier />
                                    </InputAdornment>
                                ),
                            }}
                            size={'medium'}
                            value={search}
                            onChange={(event): void => setSearch(event.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <Select
                            labelId="Color"
                            value={color}
                            onChange={handleColorChange}
                            displayEmpty
                            size={'medium'}
                            fullWidth
                            renderValue={(elementValue): JSX.Element => {
                                if (elementValue) {
                                    return <Box>{getColorItemDisplayValue(elementValue as string)}</Box>;
                                }
                                return (
                                    <Box
                                        sx={{
                                            color: 'gray',
                                        }}
                                    >
                                        Color
                                    </Box>
                                );
                            }}
                        >
                            {colorItems.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.displayValue}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <Select
                            labelId="Type"
                            value={type}
                            onChange={handleTypeChange}
                            displayEmpty
                            fullWidth
                            renderValue={(elementValue): JSX.Element => {
                                if (elementValue) {
                                    return <Box>{getTypeItemDisplayValue(elementValue as string)}</Box>;
                                }
                                return (
                                    <Box
                                        sx={{
                                            color: 'gray',
                                        }}
                                    >
                                        Type
                                    </Box>
                                );
                            }}
                        >
                            {typeItems.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.displayValue}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <Select
                            labelId="subType"
                            value={subType}
                            onChange={handleSubTypeChange}
                            displayEmpty
                            fullWidth
                            disabled={type === ''}
                            renderValue={(elementValue): JSX.Element => {
                                if (elementValue) {
                                    return <Box>{getSubTypeItemDisplayValue(elementValue as string)}</Box>;
                                }
                                return (
                                    <Box
                                        sx={{
                                            color: 'gray',
                                        }}
                                    >
                                        Sub type
                                    </Box>
                                );
                            }}
                        >
                            {subTypeItems.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.displayValue}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={0.5}>
                        <center>
                            <IconButton aria-label="refresh" size="large" style={{marginTop: ''}} onClick={
                                () => {
                                    setColor('');
                                    setType('');
                                    setSubType('');
                                    setSearch('')
                                }}>
                                <Refresh />
                            </IconButton>
                        </center>
                    </Grid>
                </Grid>
            </Box>
            {filteredList.length > 0 ? (
                <Container>
                    <DataGrid
                        rows={filteredList.sort((a, b) => {
                            // Regular expressions to match the alphabetical part and the number part
                            const alphaNumMatch = /([A-Za-z]+)(\d+)/;

                            // Split the identifiers into parts
                            const [, alphaA, numA] = a.storageId.match(alphaNumMatch) || [];
                            const [, alphaB, numB] = b.storageId.match(alphaNumMatch) || [];

                            // First, sort by the alphabetical part
                            if (alphaA < alphaB) return -1;
                            if (alphaA > alphaB) return 1;

                            // If alphabetical parts are equal, sort by the numerical part
                            return parseInt(numA, 10) - parseInt(numB, 10);
                        })}
                        columns={columns(search)}
                        getRowId={(row: LegoBrick): string => row.id}
                        style={{ cursor: 'pointer' }}
                        autoHeight
                        disableColumnResize
                        disableRowGrouping
                        hideFooter
                        onStateChange={(): void => replaceTextInDOM(document.body, 'MUI X: Missing license key', '')}
                        onCellEditCommit={async (params): Promise<void> => {
                            if (/[A-Za-z]\d/.test(params.value) || params.value === '0') {
                                const db = getFirestore();
                                const docRef = doc(db, 'bricks', String(params.id));
                                await updateDoc(docRef, { storageId: params.value.toUpperCase() });

                                console.log('updated');
                            }
                        }}
                        onCellClick={handleCellClick}
                    />
                    <Divider />
                </Container>
            ) : (
                <StyledBox mt={6} p={6}>
                    <Magnifier size="large" color="grey" />
                    <Box mt={3}>
                        <Typography variant="h3" color="grey">
                            No result for &quot;{search}&quot;
                        </Typography>
                        <Typography color="grey">Try a different search parameter</Typography>
                    </Box>
                </StyledBox>
            )}
            {(): void => replaceTextInDOM(document.body, 'MUI X: Missing license key', '')}


            {showDrawer && (
                <Drawer
                    open
                    onClose={(): void => setShowDrawer(false)}
                    title={drawerData.name}
                >
                    <BrickDetails drawerData={drawerData} fetchData={fetchData} setIsLoadingList={setIsLoading} />
                </Drawer>
                )}
        </>
    )
}

const LoadingStateComponent = (): ReactElement => (
    <Container>
        <Box p={5}>
            <LoadingBar height={50} width={'100%'}></LoadingBar>
        </Box>
        <Box px={5} py={2}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
        <Box px={5} py={2}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
        <Box px={5} py={2}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
    </Container>
);

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Container = styled(Box)`
  height: auto;
  margin-bottom: 2rem;
`;

const replaceTextInDOM = (rootElement: Node, searchText: string, replaceWith: string): void => {
    const searchRegex = new RegExp(searchText, 'g');

    const traverseNodes = (node: Node): void => {
        const nodeType = node.nodeType;
        if (nodeType === Node.TEXT_NODE) {
            if (node.nodeValue) {
                node.nodeValue = node.nodeValue.replace(searchRegex, replaceWith);
            }
        } else {
            node.childNodes.forEach(traverseNodes);
        }
    };

    traverseNodes(rootElement);
};

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default LegoPartsList;