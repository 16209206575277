import './../App.css';
import {BeckyThemeProvider} from "@coolblue-development/themes";
import Header from "./Header";
import {Box} from "@coolblue-development/becky";
import styled from "@emotion/styled";
import {Paper, AppBar, IconButton, Toolbar, Fab, useMediaQuery} from "@mui/material";
import Add from "../components/Add";
import AddIcon from '@mui/icons-material/Add';
import Login from "./Login";
import {deleteCookie, getCookie} from "../utils/cookie";
import {Plus} from "@coolblue-development/icons";
import Footer from "./Footer";

function AddOverview() {
    const isMobile = useMediaQuery('(max-width:600px)');
    if(!getCookie('user_id')) {
        return <Login />
    }

  return (
    <BeckyThemeProvider>
        <Header />

        <Box p={6} component="main">
            <StyledPaper>
                <Add />
            </StyledPaper>
        </Box>

        {!isMobile && (
            <Footer />
        )}
    </BeckyThemeProvider>
  );
}

const StyledPaper = styled(Paper)`
  min-height: 89vh;
`;

export default AddOverview;
