import React, {ReactElement, useContext, useEffect, useState} from "react";
import { initializeApp } from "firebase/app";
import {Box, Grid, MenuItem} from "@coolblue-development/becky";
import {Container, InputAdornment, TextField, Typography} from "@mui/material";

const Calculator = (): ReactElement => {
    const [money, setMoney] = useState<number>(0);
    const [points, setPoints] = useState<number>(0);

    function handleLegoPointsChange(event: React.ChangeEvent<HTMLInputElement>) {
        setMoney(Number(event.target.value) / 150)
    }

    function handleLegoPurchaseChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPoints(Number(event.target.value) * 7.5)
    }

    return (
        <>
            <Box display="flex" gap={6} p={5} marginBottom={"2rem"} style={{ overflow: "auto"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={11}>
                        <Typography variant={"h1"}>Lego points calculator</Typography>
                        <Typography variant={"h3"}>Visit <a href={'https://www.lego.com/nl-nl/my-account'}>your lego account</a> to see your points</Typography>

                        <hr></hr>

                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Typography variant={"h2"}>Points to money</Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin={"normal"}
                                            fullWidth
                                            label="Lego points"
                                            type={"number"}
                                            onChange={handleLegoPointsChange}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">points</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{marginTop: "1rem", borderColor: 'black', borderStyle: 'dashed', alignContent: 'center', width: '100%', height: '80%'}}>
                                            <center>
                                                {money === 0 ? <Typography variant={"h2"}></Typography> : <Typography variant={"h2"}>{money.toFixed(2)} euro discount</Typography>}
                                            </center>
                                        </div>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <hr></hr>
                            <Grid item xs={12} sm={6}>
                                <Typography variant={"h2"}>Lego purchase to points</Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin={"normal"}
                                            fullWidth
                                            label="Amount spent"
                                            type={"number"}
                                            onChange={handleLegoPurchaseChange}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">euro</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{marginTop: "1rem", borderColor: 'black', borderStyle: 'dashed', alignContent: 'center', width: '100%', height: '80%'}}>
                                            <center>
                                                {points === 0 ? <Typography variant={"h2"}></Typography> : <Typography sx={{justifySelf: "center", marginTop: "1.3rem"}} variant={"h2"}>{points} points</Typography>}
                                            </center>
                                        </div>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Calculator;