import './../App.css';
import {BeckyThemeProvider} from "@coolblue-development/themes";
import Header from "./Header";
import {Box} from "@coolblue-development/becky";
import styled from "@emotion/styled";
import {Paper} from "@mui/material";
import Login from "./Login";
import {getCookie} from "../utils/cookie";
import Calculator from "../components/Calculator";
import Footer from "./Footer";

function AddOverview() {
    if(!getCookie('user_id')) {
        return <Login />
    }

  return (
    <BeckyThemeProvider>
        <Header />

        <Box p={6} component="main">
            <StyledPaper>
                <Calculator />
            </StyledPaper>
        </Box>

        <Footer />
    </BeckyThemeProvider>
  );
}

const StyledPaper = styled(Paper)`
  min-height: 89vh;
`;

export default AddOverview;
