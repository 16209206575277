import React, {ReactElement, useContext, useEffect, useState} from "react";
import { initializeApp } from "firebase/app";
import {
    Box, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Typography
} from "@coolblue-development/becky";
import {Field, Form} from "react-final-form";
import {addDoc, collection, doc, getDocs, getFirestore, updateDoc} from "firebase/firestore";
import {
    SelectChangeEvent,
    ToggleButton,
    ToggleButtonGroup,
    TextField,
    Button,
    Select, CircularProgress, Container
} from "@mui/material";
import {BoxReturn, Cross} from "@coolblue-development/icons";
import {Drawers, LegoBrick} from "../types";
import styled from "@emotion/styled";
import {isProduction} from "../utils/cookie";
import {useNavigate} from "react-router-dom";
import LoadingBar from "./shared/LoadingBar";

const firebaseConfig = {
    apiKey: "AIzaSyDr9kK4sdp0GccOMwiCrhSMXctTpzMxMIw",
    authDomain: "lego-bd10d.firebaseapp.com",
    databaseURL: "https://lego-bd10d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lego-bd10d",
    storageBucket: "lego-bd10d.appspot.com",
    messagingSenderId: "573203224686",
    appId: "1:573203224686:web:1fb2ef02c9081d7e1eae7f"
};

initializeApp(firebaseConfig);

const Add = ({
     drawer,
 }: {
    drawer?: string;
}): ReactElement => {
    const [isLoading, setIsLoading] = useState(false);
    const [initializedNumberState, setInitializedNumberState] = useState('1');
    const [storageLetterState, setStorageLetterState] = useState(drawer || 'A');
    const [selectedStorageLetterState, setSelectedStorageLetterState] = useState(drawer || '');
    const [selectedStorageState, setSelectedStorageState] = useState(drawer ? drawer+initializedNumberState : '');
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedSubType, setSelectedSubType] = useState('');
    const [data, setData] = useState<LegoBrick[]>([]);
    const [drawerData, setDrawerData] = useState<Drawers[]>([]);
    const [continueState, setContinue] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);

    const fetchData = async () => {
        const db = getFirestore();

        let colRef;

        if (isProduction()) {
            colRef = collection(db, 'bricks');
        }
        else {
            colRef = collection(db, 'bricks_testing');
        }

        const bricks = await getDocs(colRef)

        let filteredBricks: any[] = [];

        bricks.docs.forEach((doc: { data: () => any; id: any; }) => {
            filteredBricks.push({ ...doc.data(), id: doc.id })
        });

        setData(filteredBricks);
    }

    const fetchDrawerData = async () => {
        const db = getFirestore();

        const colRef = collection(db, 'drawers');

        const drawers = await getDocs(colRef)

        let filteredDrawers: any[] = [];

        drawers.docs.forEach((doc: { data: () => any; id: any; }) => {
            filteredDrawers.push({ ...doc.data(), id: doc.id })
        });

        setDrawerData(filteredDrawers);
    }

    if (data.length === 0) {
        fetchData().then(() => {
            fetchDrawerData().then(() => {
                setIsLoading(false);
            });
        });
    }

    async function handleFormSubmit(values: any) {
        const db = getFirestore();
        let colRef;

        if (isProduction()) {
            colRef = collection(db, 'bricks');
        }
        else {
            colRef = collection(db, 'bricks_testing');
        }

        let color = values.color;
        if (selectedColor !== '') {
            color = selectedColor;
        }

        let type = values.type;
        if (selectedType !== '') {
            type = selectedType;
        }

        let subType = values.subType;
        if (selectedSubType !== '') {
            subType = selectedSubType;
        }

        await addDoc(colRef, {
            name: values.name,
            color: color,
            subType: subType,
            type: type,
            storageId: selectedStorageState
        });
    }

    const handleFormat = (
        event: React.MouseEvent<HTMLElement>,
        newDrawer: string,
    ) => {
        if (newDrawer) {
            setStorageLetterState(newDrawer);
        }
    };

    function getColors(index: number) {
        if (selectedStorageLetterState === '0' && index === 0 && storageLetterState === '0') {
            return {
                borderColor: "black",
                borderStyle: "solid",
                backgroundColor: "yellow",
                cursor: "pointer"
            }
        }

        if (selectedStorageLetterState !== storageLetterState) {
            return {
                borderColor: "black",
                borderStyle: "solid",
                backgroundColor: "lightgrey",
                cursor: "pointer"
            }
        }

        if (selectedStorageLetterState === 'PAB') {
            if (index === parseInt(selectedStorageState.match(/\d+/)![0])) {
                return {
                    borderColor: "black",
                    borderStyle: "solid",
                    backgroundColor: "yellow",
                    cursor: "pointer"
                }
            }
            else {
                return {
                    borderColor: "black",
                    borderStyle: "solid",
                    backgroundColor: "lightgrey",
                    cursor: "pointer"
                }
            }
        }

        if (index === parseInt(selectedStorageState.match(/\d+/)![0])-1) {
            return {
                borderColor: "black",
                borderStyle: "solid",
                backgroundColor: "yellow",
                cursor: "pointer"
            }
        }

        return {
            borderColor: "black",
            borderStyle: "solid",
            backgroundColor: "lightgrey",
            cursor: "pointer"
        }

    }

    async function handleGridClick(index: string) {
        if (drawer) {
            return;
        }

        if (index === selectedStorageState) {
            setSelectedStorageState('');
            setSelectedStorageLetterState('');
            return;
        }

        setSelectedStorageState(index);

        const test = index.match(/[A-Za-z]+/);

        setSelectedStorageLetterState((test !== null ? test[0] : '0'))
    }

    function handleColorChange(event: SelectChangeEvent<unknown>) {
        setSelectedColor(event.target.value as string);
    }
    function handleTypeChange(event: SelectChangeEvent<unknown>) {
        setSelectedType(event.target.value as string);
    }
    function handleSubTypeChange(event: SelectChangeEvent<unknown>) {
        setSelectedSubType(event.target.value as string);
    }

    const filteredSubTypeItems = data.filter((item) => {
        return item.type && item.type.toLowerCase().includes(selectedType);
    });

    const uniqueColors = new Set(data.map(item => item.color.toLowerCase()));
    const uniqueTypes = new Set(data.map(item => item.type.toLowerCase()));

    const uniqueSubTypes = new Set(filteredSubTypeItems.map(item => item.subType.toLowerCase()));

    const colorItems = Array.from(uniqueColors).map(color => ({
        value: color,
        displayValue: color.charAt(0).toUpperCase() + color.slice(1),
    }));
    const typeItems = Array.from(uniqueTypes).map(type => ({
        value: type,
        displayValue: type.charAt(0).toUpperCase() + type.slice(1),
    }));
    let subTypeItems = Array.from(uniqueSubTypes).map(subType => ({
        value: subType,
        displayValue: subType.charAt(0).toUpperCase() + subType.slice(1),
    }));

    colorItems.unshift({
        value: '',
        displayValue: '-',
    });
    typeItems.unshift({
        value: '',
        displayValue: '-',
    });
    subTypeItems.unshift({
        value: '',
        displayValue: '-',
    });

    function getColorItemDisplayValue(val: string): string {
        const match = colorItems.find((item) => item.value === val);
        if (match) {
            return match.displayValue;
        }
        return '';
    }

    function getTypeItemDisplayValue(val: string): string {
        const match = typeItems.find((item) => item.value === val);
        if (match) {
            return match.displayValue;
        }
        return '';
    }

    function getSubTypeItemDisplayValue(val: string): string {
        const match = subTypeItems.find((item) => item.value === val);
        if (match) {
            return match.displayValue;
        }
        return '';
    }

    function setNextValue() {
        if (initializedNumberState === '33') {
            setWarningOpen(true);
        }

        setSelectedStorageState(drawer+(parseInt(initializedNumberState)+1).toString());
        setInitializedNumberState((parseInt(initializedNumberState)+1).toString());
    }

    const navigate = useNavigate();

    return (
        <>
            <Dialog open={warningOpen}>
                <DialogTitle>
                    Drawer {drawer} is fully initialized.
                </DialogTitle>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => {
                        navigate('/')
                    }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" gap={6} p={5} marginBottom={"2rem"} style={{ overflow: "auto"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={11}>
                        {drawer && (
                            <Typography variant={"h1"}>Initializing drawer {drawer}</Typography>
                        )}
                        {!drawer && (
                            <Typography variant={"h1"}>Add a brick</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Form
                            onSubmit={async (values, form): Promise<void> => {
                                setIsLoading(true);
                                await handleFormSubmit(values);
                                setIsLoading(false);
                                form.reset();

                                if (!drawer) {
                                    setSelectedStorageState('');
                                    setSelectedStorageLetterState('');
                                }

                                setSelectedSubType('');
                                setSelectedType('');
                                setSelectedColor('');

                                if (continueState) {
                                    setNextValue();
                                    setContinue(false);
                                }

                                fetchData();
                            }}
                            render={({ handleSubmit, form, dirty}) => (
                                <form
                                    onSubmit={handleSubmit}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={9}>
                                            <Typography variant="h3">Details</Typography>
                                            <p></p>
                                            <Grid item xs={12}>
                                                <Field
                                                    name="name"
                                                    render={({ input, meta }): ReactElement => (
                                                        <>
                                                            <TextField
                                                                fullWidth
                                                                label="Name"
                                                                error={!meta.pristine && !!meta.error}
                                                                helperText={!meta.pristine && meta.error}
                                                                {...input}
                                                                required
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p></p>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <Field
                                                            name="color"
                                                            render={({ input, meta }): ReactElement => (
                                                                <>
                                                                    <TextField
                                                                        fullWidth
                                                                        label="Color"
                                                                        error={!meta.pristine && !!meta.error}
                                                                        helperText={!meta.pristine && meta.error}
                                                                        {...input}
                                                                        required
                                                                        disabled={!!selectedColor}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            labelId="Color"
                                                            value={selectedColor}
                                                            onChange={handleColorChange}
                                                            displayEmpty
                                                            fullWidth
                                                            renderValue={(elementValue): JSX.Element => {
                                                                if (elementValue) {
                                                                    return <Box>{getColorItemDisplayValue(elementValue as string)}</Box>;
                                                                }
                                                                return (
                                                                    <Box
                                                                        sx={{
                                                                            color: 'gray',
                                                                        }}
                                                                    >
                                                                        Color
                                                                    </Box>
                                                                );
                                                            }}
                                                        >
                                                            {colorItems.map((item) => (
                                                                <MenuItem key={item.value} value={item.value}>
                                                                    {item.displayValue}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <p></p>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <Field
                                                            name="type"
                                                            render={({ input, meta }): ReactElement => (
                                                                <>
                                                                    <TextField
                                                                        fullWidth
                                                                        label="Type"
                                                                        error={!meta.pristine && !!meta.error}
                                                                        helperText={!meta.pristine && meta.error}
                                                                        {...input}
                                                                        disabled={!!selectedType}
                                                                        required
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            labelId="Type"
                                                            value={selectedType}
                                                            onChange={handleTypeChange}
                                                            displayEmpty
                                                            fullWidth
                                                            renderValue={(elementValue): JSX.Element => {
                                                                if (elementValue) {
                                                                    return <Box>{getTypeItemDisplayValue(elementValue as string)}</Box>;
                                                                }
                                                                return (
                                                                    <Box
                                                                        sx={{
                                                                            color: 'gray',
                                                                        }}
                                                                    >
                                                                        Type
                                                                    </Box>
                                                                );
                                                            }}
                                                        >
                                                            {typeItems.map((item) => (
                                                                <MenuItem key={item.value} value={item.value}>
                                                                    {item.displayValue}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p></p>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <Field
                                                            name="subType"
                                                            render={({ input, meta }): ReactElement => (
                                                                <>
                                                                    <TextField
                                                                        fullWidth
                                                                        label="SubType"
                                                                        error={!meta.pristine && !!meta.error}
                                                                        helperText={!meta.pristine && meta.error}
                                                                        {...input}
                                                                        disabled={!!selectedSubType}
                                                                        required
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            labelId="subType"
                                                            value={selectedSubType}
                                                            onChange={handleSubTypeChange}
                                                            displayEmpty
                                                            fullWidth
                                                            renderValue={(elementValue): JSX.Element => {
                                                                if (elementValue) {
                                                                    return <Box>{getSubTypeItemDisplayValue(elementValue as string)}</Box>;
                                                                }
                                                                return (
                                                                    <Box
                                                                        sx={{
                                                                            color: 'gray',
                                                                        }}
                                                                    >
                                                                        SubType
                                                                    </Box>
                                                                );
                                                            }}
                                                        >
                                                            {subTypeItems.map((item) => (
                                                                <MenuItem key={item.value} value={item.value}>
                                                                    {item.displayValue}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <Field
                                                        name="storageId"
                                                        render={({ input, meta }): ReactElement => (
                                                            <>
                                                                <Typography variant="h3">Storage</Typography>
                                                                <p></p>
                                                                {drawerData.length < 1 && (
                                                                    <LoadingStateComponent></LoadingStateComponent>
                                                                )}
                                                                {!drawer && drawerData.length > 1 && (
                                                                    <ToggleButtonGroup
                                                                        color="primary"
                                                                        value={storageLetterState}
                                                                        exclusive
                                                                        onChange={handleFormat}
                                                                        aria-label="text alignment"
                                                                        fullWidth
                                                                    >
                                                                        {drawerData
                                                                            .filter(item => item.type === 'drawer')
                                                                            .sort((a, b) => a.name.localeCompare(b.name))
                                                                            .map(item => (
                                                                                <ToggleButton value={item.name}>{item.name}</ToggleButton>
                                                                            ))
                                                                        }

                                                                        {drawerData.length > 1 && (
                                                                            <ToggleButton value="PAB"><ResponsiveImage src="/PAB1.png" alt="Lego avatar"/></ToggleButton>
                                                                        )}
                                                                        {drawerData.length > 1 && (
                                                                            <ToggleButton value="0"><BoxReturn></BoxReturn></ToggleButton>
                                                                        )}
                                                                    </ToggleButtonGroup>
                                                                )}
                                                                <p></p>

                                                                {storageLetterState === '0' && (
                                                                    <Grid container lineHeight={16.5}>
                                                                        <Grid item xs={12} style={getColors(0)} onClick={() => {handleGridClick('0')}}>
                                                                            <center>
                                                                                <h2>Empty</h2>
                                                                            </center>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {storageLetterState === 'PAB' && (
                                                                    <Grid container lineHeight={2.5}>
                                                                        {drawerData
                                                                            .filter(item => item.type === 'pab')
                                                                            .sort((a, b) => a.name.localeCompare(b.name))
                                                                            .map(item => (
                                                                                <Grid item xs={4} style={getColors(Number(item.name))} onClick={() => {handleGridClick('PAB'+item.name)}}>
                                                                                    <center>
                                                                                        <ResponsiveImage src="/PAB1.png" alt="Lego avatar"/> <h2 style={{marginTop: "-68%"}}>{item.name}</h2>
                                                                                    </center>
                                                                                </Grid>
                                                                            ))
                                                                        }
                                                                    </Grid>
                                                                )}

                                                                {storageLetterState !== '0' && storageLetterState !== 'PAB' && (
                                                                    <Grid container lineHeight={0.1}>
                                                                        {Array.from({ length: 32 }, (_, index) => (
                                                                            <Grid item xs={3} style={getColors(index)} onClick={() => {handleGridClick(storageLetterState+(index+1))}}>
                                                                                <center>
                                                                                    <h2>{storageLetterState}{index+1}</h2>
                                                                                </center>
                                                                            </Grid>
                                                                        ))}
                                                                        <Grid item xs={12} style={getColors(32)} onClick={() => {handleGridClick(storageLetterState+(32+1))}}>
                                                                            <center>
                                                                                <h2>{storageLetterState}{32+1}</h2>
                                                                            </center>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {drawer && (
                                                        <Button fullWidth variant="outlined" color={!dirty ? 'warning' : 'primary'} type={!dirty ? 'button' : 'submit'} onClick={() => {
                                                            if (!dirty) {
                                                                setNextValue();
                                                            }
                                                        }}>
                                                            {isLoading ? <StyledCircularProgress color={'primary'} /> : !dirty ? 'Skip' : 'Add'}
                                                        </Button>
                                                    )}
                                                    {drawer && (
                                                        <p></p>
                                                    )}
                                                    {drawer && dirty && (
                                                        <Button fullWidth variant="outlined" color={'primary'} type={'submit'} onClick={() => {setContinue(true)}}>
                                                            {isLoading ? <StyledCircularProgress color={'primary'} /> : 'Add & continue'}
                                                        </Button>
                                                    )}
                                                    {!drawer && (
                                                        <Button fullWidth variant="outlined" color={"primary"} type="submit" disabled={!dirty || !selectedStorageState}>
                                                            {isLoading ? <StyledCircularProgress color={'primary'} /> : 'Add'}
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const LoadingStateComponent = (): ReactElement => (
    <Container>
        <Box p={5}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
    </Container>
);

export const StyledCircularProgress = styled(CircularProgress)`
  height: 20px !important;
  width: 20px !important;
`;

const ResponsiveImage = styled.img`
  width: 100%;
  display: block; // Removes any whitespace below the image
`;


export default Add;