import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {beckyTheme, BeckyThemeProvider} from "@coolblue-development/themes";
import {RouterProvider} from "react-router-dom";
import router from "./router";
import {ServiceContextProvider} from "./contexts/serviceContext";
import {LicenseInfo} from "@mui/x-data-grid-premium";
import { muiPremiumKey } from './etc/secrets.json';

const rootElement = document.getElementById('root');

if (!rootElement) {
    throw new Error('No root element found');
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
      <ServiceContextProvider>
          <BeckyThemeProvider theme={beckyTheme}>
              <RouterProvider router={router} />
          </BeckyThemeProvider>
      </ServiceContextProvider>
  </React.StrictMode>
);
