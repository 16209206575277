import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import Overview from "./layouts/Overview";
import {routes} from "./routes";
import AddOverview from "./layouts/AddOverview";
import Login from "./layouts/Login";
import CalculatorOverview from "./layouts/CalculatorOverview";
import InitializeOverview from "./layouts/InitializeOverview";
import SettingsOverview from "./layouts/SettingsOverview";

export default createBrowserRouter([
  {
    path: routes.ROOT,
    element: <Overview />,
  },
  {
    path: routes.ADD,
    element: <AddOverview />,
  },
  {
    path: routes.CALCULATOR,
    element: <CalculatorOverview />,
  },
  {
    path: routes.INITIALIZE,
    element: <InitializeOverview />,
  },
  {
    path: routes.SETTINGS,
    element: <SettingsOverview />,
  }
]);
