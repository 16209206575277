import { Box } from '@coolblue-development/becky';
import styled from '@emotion/styled';

export const LoadingBar = ({ height, width }: { height: number; width: number | string }): JSX.Element => (
    <Placeholder sx={{ height, width }}>
        <AnimatedBox></AnimatedBox>
    </Placeholder>
);

const Placeholder = styled(Box)`
  border-radius: 3px;
  background-color: #eee;
  overflow: hidden;
`;

const AnimatedBox = styled(Box)`
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 100px;
  position: relative;
`;

export default LoadingBar;