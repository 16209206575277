import {
    AppBar,
    Box,
    Button,
    Fab,
    List,
    ListItem,
    ListItemText,
    Stack,
    Switch,
    Toolbar,
    Drawer,
    useMediaQuery, Grid, ToggleButton
} from '@mui/material';
import React, {MouseEventHandler, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "@emotion/styled";
import {Cross, Plus} from "@coolblue-development/icons";
import {getInitializeRoute} from "../routes";
import AddIcon from "@mui/icons-material/Add";
import {Dialog, DialogActions, DialogTitle, IconButton, Menu} from "@coolblue-development/becky";
import {Home, MenuBook} from "@mui/icons-material";
import {Drawers} from "../types";
import {collection, getDocs, getFirestore} from "firebase/firestore";

const Footer = (): JSX.Element => {
    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawers, setDrawers] = useState<Drawers[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchDrawerData = async () => {
        const db = getFirestore();

        const colRef = collection(db, 'drawers');

        const drawers = await getDocs(colRef)

        let filteredDrawers: any[] = [];

        drawers.docs.forEach((doc: { data: () => any; id: any; }) => {
            filteredDrawers.push({ ...doc.data(), id: doc.id })
        });

        setDrawers(filteredDrawers);
    }

    if (drawers.length === 0 && dialogOpen) {
        fetchDrawerData().then(() => {
            setIsLoading(false);
        });
    }

    const isMobile = useMediaQuery('(max-width:600px)');
    const isListPage = window.location.pathname === '/';

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuItems = (
        <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
                <ListItem button onClick={() => navigate('/calculator')}>Calculator</ListItem>
                <ListItem button onClick={() => setDialogOpen(true)}>Initialize</ListItem>
                <ListItem button onClick={() => navigate('/settings')}>Settings</ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                <Toolbar>
                    <Button variant="text" color="info" onClick={() => {navigate('/')}}>
                        <Home />
                    </Button>
                    {isListPage && (
                        <StyledFab color="secondary" aria-label="add" onClick={() => {navigate('/add')}}>
                            <AddIcon />
                        </StyledFab>
                    )}
                    <Box sx={{ flexGrow: 1 }} />

                    {isMobile ? (
                        <>
                            <Button variant="text" color="info" onClick={toggleDrawer(true)}>
                                <MenuBook />
                            </Button>
                            <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer(false)}>
                                {menuItems}
                            </Drawer>
                        </>
                    ) : (
                        <>
                            <Button variant="text" color="info" onClick={() => navigate('/calculator')}>
                                Calculator
                            </Button>
                            <Button variant="text" color="info" onClick={() => setDialogOpen(true)}>
                                Initialize
                            </Button>
                            <Button variant="text" color="info" onClick={() => navigate('/settings')}>
                                Settings
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>

            <Dialog open={dialogOpen} onClose={() => {setDialogOpen(false)}}>
                <DialogTitle>
                    Which drawer would you like to initialize?
                    <IconButton onClick={() => {setDialogOpen(false)}} preset="text" size="small" aria-label="Close dialog">
                        <Cross />
                    </IconButton>
                </DialogTitle>
                <DialogActions>
                    {isMobile ? (
                        <Grid container spacing={3}>
                            {drawers
                                .filter(item => item.type === 'drawer')
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(item => (
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="info" onClick={() => {
                                            setDialogOpen(false);
                                            navigate(getInitializeRoute(item.name));
                                        }}>
                                            {item.name}
                                        </Button>
                                    </Grid>
                                ))
                            }
                            <Grid item xs={12}>
                                <Button variant="contained" fullWidth color="primary" onClick={() => {setDialogOpen(false)}}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    ): (
                        <>
                            <Button variant="contained" color="primary" onClick={() => {setDialogOpen(false)}}>
                                Cancel
                            </Button>
                            {drawers
                                .filter(item => item.type === 'drawer')
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(item => (
                                    <Button variant="contained" color="info" onClick={() => {
                                        setDialogOpen(false);
                                        navigate(getInitializeRoute(item.name));
                                    }}>
                                        {item.name}
                                    </Button>
                                ))
                            }
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};


const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
});

export default Footer;
