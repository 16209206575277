import React from 'react';
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";

export function LogoSmall(): React.JSX.Element {
  const navigate = useNavigate();
  return <a onClick={(): void => {
    navigate('/');
  }}><ResponsiveImage src="../logo.png" alt="Lego avatar"/></a>;
}

export function Logo(): React.JSX.Element {
  return <img src="logo.png" alt="Lego avatar" style={{ width: '160px', height: 'auto' }} />;
}

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block; // Removes any whitespace below the image
`;