import React, {ReactElement, useEffect, useState} from "react";
import {Drawers, LegoBrick} from "../types";
import {doc, getFirestore, updateDoc, deleteDoc, collection, getDocs} from "firebase/firestore";
import {Field, Form, useFormState} from "react-final-form";
import {
    CircularProgress,
    ToggleButton,
    ToggleButtonGroup,
    Button,
    Divider,
    Grid,
    TextField,
    Typography,
    Container
} from "@mui/material";
import styled from "@emotion/styled";
import {BoxReturn, Cross} from "@coolblue-development/icons";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@coolblue-development/becky";
import {isProduction} from "../utils/cookie";
import LoadingBar from "../components/shared/LoadingBar";

export type drawerData = {
    drawerData: {
        name: string;
        storageId: string;
        id: string;
        color: string;
        type: string;
        subType: string;
    };
    fetchData: () => Promise<void>;
    setIsLoadingList: (value: boolean) => void;
};

const BrickDetails = ({ drawerData, fetchData, setIsLoadingList }: drawerData): ReactElement => {
    const [drawerDataState, setDrawerData] = useState<LegoBrick>(drawerData);
    const [drawers, setDrawers] = useState<Drawers[]>([]);
    const [previousStorageId, setPreviousStorageId] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        setContent("Are you sure you want to delete '" + drawerDataState.name + "'? This action cannot be undone.");
    }, []);

    const test = drawerDataState.storageId.match(/[A-Za-z]+/);
    // @ts-ignore
    const [storageLetterState, setStorageLetterState] = useState((test !== null ? test[0] : '0'));

    const fetchDrawerData = async () => {
        const db = getFirestore();

        const colRef = collection(db, 'drawers');

        const drawers = await getDocs(colRef)

        let filteredDrawers: any[] = [];

        drawers.docs.forEach((doc: { data: () => any; id: any; }) => {
            filteredDrawers.push({ ...doc.data(), id: doc.id })
        });

        setDrawers(filteredDrawers);
    }

    if (drawers.length === 0) {
        fetchDrawerData().then(() => {
            setIsLoading(false);
        });
    }

    function getColors(index: number) {
        if (storageLetter === '0' && index === 0 && storageLetterState === '0') {
            return {
                borderColor: "black",
                borderStyle: "solid",
                backgroundColor: "yellow",
                cursor: "pointer"
            }
        }

        if (storageLetter !== storageLetterState) {
            return {
                borderColor: "black",
                borderStyle: "solid",
                backgroundColor: "lightgrey",
                cursor: "pointer"
            }
        }

        if (storageLetterState === 'PAB') {
            if (index === parseInt(drawerDataState.storageId.match(/\d+/)![0])) {
                return {
                    borderColor: "black",
                    borderStyle: "solid",
                    backgroundColor: "yellow",
                    cursor: "pointer"
                }
            }
            else {
                return {
                    borderColor: "black",
                    borderStyle: "solid",
                    backgroundColor: "lightgrey",
                    cursor: "pointer"
                }
            }
        }

        // @ts-ignore
        const numberPart = drawerDataState.storageId.match(/\d+/)[0];

        if (index+1 === parseInt(numberPart)) {
            return {
                borderColor: "black",
                borderStyle: "solid",
                backgroundColor: "yellow",
                cursor: "pointer"
            }
        }

        return {
            borderColor: "black",
            borderStyle: "solid",
            backgroundColor: "lightgrey",
            cursor: "pointer"
        }


    }

    let storageLetter = '0';
    if (drawerDataState.storageId !== '0') {
        // @ts-ignore
        storageLetter = drawerDataState.storageId.match(/[A-Za-z]+/)[0]
    }

    async function handleGridClick(index: string) {
        const db = getFirestore();
        let docRef;

        if (isProduction()) {
            docRef = doc(db, 'bricks', String(drawerDataState.id));
        }
        else {
            docRef = doc(db, 'bricks_testing', String(drawerDataState.id));
        }
        await updateDoc(docRef, {storageId: index});

        setPreviousStorageId(drawerDataState.storageId);

        setDrawerData({...drawerDataState, storageId: index})

        fetchData();
        console.log('updated');
    }

    async function handleUndo() {
        const db = getFirestore();

        let docRef;

        if (isProduction()) {
            docRef = doc(db, 'bricks', String(drawerDataState.id));
        }
        else {
            docRef = doc(db, 'bricks_testing', String(drawerDataState.id));
        }

        await updateDoc(docRef, {storageId: previousStorageId});

        setDrawerData({...drawerDataState, storageId: previousStorageId})

        setPreviousStorageId('');
        fetchData();
        console.log('updated');
    }

    async function handleFormSubmit(values: any) {
        const db = getFirestore();

        let docRef;

        if (isProduction()) {
            docRef = doc(db, 'bricks', String(drawerDataState.id));
        }
        else {
            docRef = doc(db, 'bricks_testing', String(drawerDataState.id));
        }

        await updateDoc(docRef, values);

        setDrawerData({...drawerDataState, ...values})
        fetchData();
        console.log('updated');
    }

    async function handleDelete(id: string) {
        setIsLoading(true);
        const db = getFirestore();

        let deleteDocRef;

        if (isProduction()) {
            deleteDocRef = doc(db, 'bricks', id);
        }
        else {
            deleteDocRef = doc(db, 'bricks_testing', id);
        }

        await deleteDoc(deleteDocRef)

        window.location.reload();
        setIsLoading(false);
    }

    const handleFormat = (
        event: React.MouseEvent<HTMLElement>,
        newDrawer: string,
    ) => {
        if (newDrawer) {
            setStorageLetterState(newDrawer);
        }
    };

    return (
        <>
            <div style={{marginBottom: "2rem"}}>
                <Grid container spacing={5}>

                    {previousStorageId!=='' &&
                        <Grid item xs={12}>
                            <p>You have changed the location from <strong>{previousStorageId}</strong> {">"} <strong>{drawerDataState.storageId}</strong>, <a style={{color: "blue", cursor: "pointer"}} onClick={() => {handleUndo()}}>undo</a></p>
                        </Grid>
                    }

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h3">Storage</Typography>
                        <p></p>
                        {drawers.length < 1 && (
                            <LoadingStateComponent></LoadingStateComponent>
                        )}
                        {drawers.length > 1 && (
                            <ToggleButtonGroup
                                color="primary"
                                value={storageLetterState}
                                exclusive
                                onChange={handleFormat}
                                aria-label="text alignment"
                                fullWidth
                            >
                                {drawers
                                    .filter(item => item.type === 'drawer')
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(item => (
                                        <ToggleButton value={item.name}>{item.name}</ToggleButton>
                                    ))
                                }
                                {drawers.length > 1 && (
                                    <ToggleButton value="PAB"><ResponsiveImage src="/PAB1.png" alt="Lego avatar"/></ToggleButton>
                                )}
                                {drawers.length > 1 && (
                                    <ToggleButton value="0"><BoxReturn></BoxReturn></ToggleButton>
                                )}
                            </ToggleButtonGroup>
                        )}
                        <p></p>

                        {storageLetterState === '0' && (
                            <Grid container lineHeight={16.5}>
                                <Grid item xs={12} style={getColors(0)} onClick={() => {handleGridClick('0')}}>
                                    <center>
                                        <h2>Empty</h2>
                                    </center>
                                </Grid>
                            </Grid>
                        )}

                        {storageLetterState === 'PAB' && (
                            <Grid container lineHeight={2.5}>
                                {drawers
                                    .filter(item => item.type === 'pab')
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(item => (
                                        <Grid item xs={4} style={getColors(Number(item.name))} onClick={() => {handleGridClick('PAB'+item.name)}}>
                                            <center>
                                                <ResponsiveImage src="/PAB1.png" alt="Lego avatar"/> <h2 style={{marginTop: "-68%"}}>{item.name}</h2>
                                            </center>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        )}

                        {storageLetterState !== '0' && storageLetterState !== 'PAB' && (
                            <Grid container lineHeight={0.1}>
                                {Array.from({ length: 32 }, (_, index) => (
                                    <Grid item xs={3} style={getColors(index)} onClick={() => {handleGridClick(storageLetterState+(index+1))}}>
                                        <center>
                                            <h2>{storageLetterState}{index+1}</h2>
                                        </center>
                                    </Grid>
                                ))}
                                <Grid item xs={12} style={getColors(32)} onClick={() => {handleGridClick(storageLetterState+(32+1))}}>
                                    <center>
                                        <h2>{storageLetterState}{32+1}</h2>
                                    </center>
                                </Grid>
                            </Grid>
                        )}

                    </Grid>
                    <Grid item xs={12} sm={0.5}>
                        <Divider orientation="vertical" color={"lightgrey"} />
                        <Divider orientation="horizontal" color={"lightgrey"} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Typography variant="h3">Brick data</Typography>
                        <p></p>
                        <Form
                            onSubmit={async (values): Promise<void> => {
                                setIsLoading(true);
                                await handleFormSubmit(values);
                                setIsLoading(false);
                            }}
                            initialValues={{
                                name: drawerDataState.name,
                                color: drawerDataState.color,
                                type: drawerDataState.type,
                                subType: drawerDataState.subType,
                            }}
                            render={({ handleSubmit, form, dirty, pristine}) => (
                                <form
                                    onSubmit={handleSubmit}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field
                                                name="name"
                                                render={({ input, meta }): ReactElement => (
                                                    <>
                                                        <TextField
                                                            fullWidth
                                                            label="Name"
                                                            error={!meta.pristine && !!meta.error}
                                                            helperText={!meta.pristine && meta.error}
                                                            {...input}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name="color"
                                                render={({ input, meta }): ReactElement => (
                                                    <>
                                                        <TextField
                                                            fullWidth
                                                            label="Color"
                                                            error={!meta.pristine && !!meta.error}
                                                            helperText={!meta.pristine && meta.error}
                                                            {...input}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name="type"
                                                render={({ input, meta }): ReactElement => (
                                                    <>
                                                        <TextField
                                                            fullWidth
                                                            label="Type"
                                                            error={!meta.pristine && !!meta.error}
                                                            helperText={!meta.pristine && meta.error}
                                                            {...input}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name="subType"
                                                render={({ input, meta }): ReactElement => (
                                                    <>
                                                        <TextField
                                                            fullWidth
                                                            label="SubType"
                                                            error={!meta.pristine && !!meta.error}
                                                            helperText={!meta.pristine && meta.error}
                                                            {...input}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button fullWidth variant="outlined" type="submit" disabled={!dirty}>
                                                {isLoading ? <StyledCircularProgress /> : 'Change'}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button fullWidth variant="outlined" color="error" onClick={() => {
                                                setDialogOpen(true);
                                            }
                                            }>
                                                {isLoading ? <StyledCircularProgress /> : 'Delete'}
                                            </Button>

                                            <Dialog open={dialogOpen} onClose={() => {setDialogOpen(false)}}>
                                                <DialogTitle>
                                                    Are you sure?
                                                    <IconButton onClick={() => {setDialogOpen(false)}} preset="text" size="small" aria-label="Close dialog">
                                                        <Cross />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>{content}</DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button variant="contained" onClick={() => {setDialogOpen(false)}}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="contained" color="error" onClick={
                                                        async (): Promise<void> => await handleDelete(drawerDataState.id)
                                                    }>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

const LoadingStateComponent = (): ReactElement => (
    <Container>
        <Box p={5}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
    </Container>
);

export const StyledCircularProgress = styled(CircularProgress)`
  height: 20px !important;
  width: 20px !important;
`;

const ResponsiveImage = styled.img`
  width: 100%;
  display: block; // Removes any whitespace below the image
`;

export default BrickDetails;