/*
     * General utils for managing cookies in Typescript.
     */

const token = '0092b9cffcd813efd6a0179af2cb391a7fb266469b23f98a321cbff5af6a7e74c5dae578f6470248ac170cc7613306a8';

export function setCookie(name: string, val: string) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name+token+"="+value+"; expires="+date.toUTCString()+"; path=/";
}

export function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name+token + "=");

    if (parts.length == 2) {
        return parts.pop()?.split(";").shift();
    }
}

export function getEnvironmentCookie() {
    const value = "; " + document.cookie;
    const parts = value.split("; " + 'isTesting'+token + "=");

    if (parts.length === 1) {
        return 'production';
    }

    return 'testing'
}

export function isProduction() {
    return getEnvironmentCookie() === 'production';
}

export function deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name+token+"=; expires="+date.toUTCString()+"; path=/";
}