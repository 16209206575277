import {Button, ListItemText, Stack, Switch} from '@mui/material';
import React, {MouseEventHandler, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import {
    AppBar,
    Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    IconButton,
    ListItemAvatar,
    Menu,
    MenuItem,
    Typography,
} from '@coolblue-development/becky';
import { LogoSmall } from '../components/Logo';
import {deleteCookie, getCookie, isProduction, setCookie} from "../utils/cookie";
import styled from "@emotion/styled";

const Header = (): JSX.Element => {
    return <AppBar logo={<LogoSmall />} avatar={<MenuButton />} position="sticky" title="Lego finder" />;
};

const MenuButton = (): JSX.Element => {
    const user = getCookie('user_name');

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const initial = user ? user.substring(0, 1) : 'U';

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleEnvironmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
       if (event.target.checked) {
           deleteCookie('isTesting');
           window.location.reload();
        } else {
            setCookie('isTesting', 'true')
            window.location.reload();
        }
    };

    return (
        <>
            <StyledIconButton
                preset="text"
                contextBackground="primary"
                id="divider-button"
                onClick={handleClick}
                aria-controls={open ? 'divider-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Avatar>{initial}</Avatar>
            </StyledIconButton>
            <Menu
                id="divider-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'divider-button',
                }}
            >
                <MenuItem onClick={handleClose}>
                    <ListItemAvatar>
                        <Avatar>{initial}</Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ padding: 2 }}>
                        <Typography variant="h4">
                            {user || 'User'}
                        </Typography>
                    </ListItemText>
                </MenuItem>
                {getCookie('user_role') === 'admin' && (
                    <MenuItem>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Switch onChange={handleEnvironmentChange} checked={isProduction()} color="error" />
                            <Typography>{isProduction() ? 'Production' : 'Testing'}</Typography>
                        </Stack>
                    </MenuItem>
                )}
                <Divider />
                {user ? (
                    <MenuItem onClick={() => {deleteCookie('user_id'); deleteCookie('user_name'); window.location.reload()}}>
                        <ListItemText>Logout</ListItemText>
                    </MenuItem>
                ) : (
                    <MenuItem
                        onClick={(): void => {
                            navigate('/login');
                        }}
                    >
                        <ListItemText>Login</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

const StyledIconButton = styled(IconButton)`

  margin-top: -10%;

  @media (max-width: 600px) {
    margin-top: -22%; 
    margin-left: -40%;
    font-size: 20rem;
  }
`;

export default Header;
