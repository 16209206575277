import React, {ReactElement, useContext, useEffect, useState} from "react";
import { initializeApp } from "firebase/app";
import {
    Box, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem
} from "@coolblue-development/becky";
import {Button, Container, InputAdornment, TextField, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Add from "./Add";
import {collection, getFirestore, query, where, getDocs, deleteDoc, doc, updateDoc} from "firebase/firestore";
import {isProduction} from "../utils/cookie";
import {Drawers, LegoBrick} from "../types";
import LoadingBar from "./shared/LoadingBar";
import {Cross} from "@coolblue-development/icons";
import {getInitializeRoute} from "../routes";

const Initialize = (): ReactElement => {
    const { drawer = '' } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [deleted, setDeleted] = useState(false);
    const [data, setData] = useState<LegoBrick[]>([]);
    const [drawers, setDrawers] = useState<Drawers[]>([]);
    const [warningOpen, setWarningOpen] = useState(true);
    const db = getFirestore();
    const navigate = useNavigate();

    const fetchData = async () => {
        let colRef;

        if (isProduction()) {
            colRef = collection(db, 'bricks');
        }
        else {
            colRef = collection(db, 'bricks_testing');
        }

        const bricks = await getDocs(colRef)

        let filteredBricks: any[] = [];

        bricks.docs.forEach((doc: { data: () => any; id: any; }) => {
            filteredBricks.push({ ...doc.data(), id: doc.id })
        });

        setData(filteredBricks);
    }

    const fetchDrawerData = async () => {
        const db = getFirestore();

        const colRef = collection(db, 'drawers');

        const drawers = await getDocs(colRef)

        let filteredDrawers: any[] = [];

        drawers.docs.forEach((doc: { data: () => any; id: any; }) => {
            filteredDrawers.push({ ...doc.data(), id: doc.id })
        });

        setDrawers(filteredDrawers);
    }

    if (data.length === 0) {
        fetchData().then(() => {
            fetchDrawerData().then(() => {
                setIsLoading(false);
            });
        });
    }

    if (isLoading) return <LoadingStateComponent />;

    const eligibleDrawers = drawers.map((drawer: Drawers) => {
        if (drawer.name === 'pab') {
            return;
        }

        return drawer.name;
    });

    if (!deleted && !warningOpen) {
        data.forEach(async (brick: LegoBrick) => {
            if (extractLetters(brick.storageId) === drawer) {
                let docRef;

                if (isProduction()) {
                    docRef = doc(db, 'bricks', String(brick.id));
                }
                else {
                    docRef = doc(db, 'bricks_testing', String(brick.id));
                }

                await updateDoc(docRef, {...brick, storageId: '0'});
            }
        });

        setDeleted(true);
    }

    if (!eligibleDrawers.includes(drawer)) {
        return (
            <>
                <Dialog open={warningOpen}>
                    <DialogTitle>
                        Drawer {drawer} is not a eligible for initialization.
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>Go back or change the drawer</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => {
                            navigate('/')
                        }}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    if (warningOpen) {
        return (
            <>
                <Dialog open={warningOpen} onClose={() => {navigate('/')}}>
                    <DialogTitle>
                        Are you sure you want to do this?
                        <IconButton onClick={() => {navigate('/')}} preset="text" size="small" aria-label="Close dialog">
                            <Cross />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>This will delete all data in drawer {drawer}.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => {
                            navigate('/')
                        }}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="error" onClick={() => {setWarningOpen(false)}}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    return (
        <>
            <Box display="flex" gap={6} p={5} marginBottom={"2rem"} style={{ overflow: "auto"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={11}>
                        <Add drawer={drawer} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

function extractLetters(input: string): string {
    const letters = input.match(/[A-Za-z]/g) || [];
    return letters.join('');
}

const LoadingStateComponent = (): ReactElement => (
    <Container>
        <Box p={5}>
            <LoadingBar height={50} width={'100%'}></LoadingBar>
        </Box>
        <Box px={5} py={2}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
        <Box px={5} py={2}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
        <Box px={5} py={2}>
            <LoadingBar height={30} width={'100%'}></LoadingBar>
        </Box>
    </Container>
);

export default Initialize;