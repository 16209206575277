import './../App.css';
import {BeckyThemeProvider} from "@coolblue-development/themes";
import Header from "./Header";
import {Box} from "@coolblue-development/becky";
import styled from "@emotion/styled";
import {AppBar, Button, Fab, IconButton, Paper, Toolbar} from "@mui/material";
import LegoPartsList from "../components/LegoPartsList";
import Login from "./Login";
import {getCookie} from "../utils/cookie";
import {Plus} from "@coolblue-development/icons";
import AddIcon from "@mui/icons-material/Add";
import {useNavigate} from "react-router-dom";
import Footer from "./Footer";

function Overview() {
    if(!getCookie('user_id')) {
        return <Login />
    }

    return (
    <BeckyThemeProvider>
        <Header />

        <Box p={6} component="main">
            <StyledPaper>
                <LegoPartsList />
            </StyledPaper>
        </Box>

        <Footer />
    </BeckyThemeProvider>
  );
}

const StyledPaper = styled(Paper)`
  min-height: 89vh;
`;

export default Overview;
